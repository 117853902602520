<template>
  <div>
    <!-- Table Container Card -->
    <b-card>
      <div class="mb-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-link class="btn btn-primary d-flex justify-content-center align-items-center" to="/games/create">
                <span class="text-nowrap"> اضافه کردن بازی</span>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Table -->
      <LoadingComponent v-if="isLoadingTwo" />
      <div v-else>
        <div class="pt-1 pb-1 overflow" v-if="games.length > 0">
          <vue-good-table :sort-options="{ enabled: false }" :columns="columns" :rows="games" :rtl="false"
            class="w-100">
            <div slot="emptystate" class="w-100 text-center">
              موردی یافت نشد
            </div>
            <template slot="table-row" slot-scope="props" class="mx-auto">
              <!-- Column: Name -->
              <span v-if="props.column.field === 'id'" class="text-nowrap">
                <span class="text-nowrap">{{ props.row.id }}</span>
              </span>
              <span v-if="props.column.field === 'post_id'" class="text-nowrap">
                <span class="text-nowrap">{{ props.row.post_id }}</span>
              </span>
              <span v-if="props.column.field === 'title'" class="text-nowrap">
                <span class="text-nowrap">{{ props.row.title }}</span>
              </span>
              <span v-if="props.column.field === 'created_at'" class="text-nowrap">
                <span class="text-nowrap">{{ props.row.created_at }}</span>
              </span>
              <span v-if="props.column.field === 'url'" class="text-nowrap">
                <span class="text-nowrap">
                  <p class="mb-0" dir="ltr">
                    {{ `https://${website_url}/games/` + props.row.id + "/" + props.row.title.split(' ').join('-') }}
                  </p>
                </span>
                <br>
                <b-button @click="doCopy(props.row.title, props.row.id)" variant="primary"
                  :disabled="!props.row.title">کپی متن</b-button>
              </span>
              <span v-if="props.column.field === 'status'" class="text-nowrap">
                <b-badge :variant="props.row.status == 1 ? 'success' : 'danger'">
                  {{ props.row.status == 1 ? "انتشار" : "پیش نویس" }}
                </b-badge>
              </span> 

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'" style="width: 100px !important">
                <span>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret :right="true">
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                    </template>
                    <b-dropdown-item @click="moveToEditPage(props.row.id)">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>ویرایش</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deletePrompt(props.row.id)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>حذف</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
            </template>
          </vue-good-table>
        </div>
        <div v-else>
          <h3 class="text-center">موردی یافت نشد</h3>
        </div>
        <b-pagination v-model="currentPage" :total-rows="totalGames" :per-page="perPage" first-number last-number
          align="left" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0 p-0" dir="ltr">

          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>

          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BSpinner,
  BRow,
  BCol,
  BButton,
  BPagination,
  BTable,
  BDropdown,
  BDropdownItem,
  BLink,
  BBadge,
} from "bootstrap-vue";
import axios from "axios";
import { VueGoodTable } from "vue-good-table";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LoadingComponent from "../../components/LoadingComponent.vue";
import copy from 'copy-text-to-clipboard';
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCard,
    BSpinner,
    BRow,
    BTable,
    BCol,
    BButton,
    BPagination,
    BDropdownItem,
    BDropdown,
    ToastificationContent,
    LoadingComponent,
    BLink,
    BBadge,
  },
  data() {
    return {
      isLoading: false,
      isLoadingTwo: false,
      website_url: process.env.VUE_APP_BASE_URL.split('https://api.')[1],
      games: [],
      gamesLength: 0,
      gameTray: [],
      totalGames: 0,
      perPage: 15,
      currentPage: 1,
      columns: [
        {
          label: "شناسه",
          field: "id",
        },
        {
          label: "شناسه پست",
          field: "post_id",
        },
        {
          label: "عنوان پست",
          field: "title",
        },
        {
          label: "تاریخ انتشار",
          field: "created_at",
        },
        {
          label: "آدرس",
          field: "url",
        },
        {
          label: "وضعیت",
          field: "status",
        },
        {
          label: "بیشتر",
          field: "action",
        },
      ],
    };
  },
  methods: {
    getAllGames() {
      this.isLoadingTwo = true;
      axios
        .get("/api/v1/admin/games", { params: { page: this.currentPage } })
        .then((response) => {
          this.gamesLength = response.data.data.length;
          this.totalGames = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          if (this.gamesLength > 0) {
            this.gameTray = response.data.data;
            this.games = [];
            this.gameTray.forEach((item) => {
              this.games.push({
                id: item.id,
                post_id: item.post_id,
                title: item.title,
                created_at: item.created_at,
                status: item.status,
              });
            });
          }
          this.isLoadingTwo = false;
        });
    },
    deletePrompt(id) {
      this.$bvModal
        .msgBoxConfirm('آیا از حذف مطمئن هستید؟', {
          title: '',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'بله',
          cancelTitle: 'خیر',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.deleteGameItem(id)
          }
        })
    },
    deleteGameItem(id) {
      axios
        .post(`/api/v1/admin/games/${id}/delete`)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "محصول حذف شد",
              icon: "CheckIcon",
              text: "محصول با موفیقت حذف شد.",
              variant: "info",
            },
          });
          this.getAllGames();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    moveToEditPage(id) {
      router.push(`/games/update/${id}`);
    },
    doCopy(text, id) {

      copy(`https://${process.env.VUE_APP_BASE_URL.split('https://api.')[1]}/games/` + id + "/" + text.split(' ').join('-'));

      this.$toast({
        component: ToastificationContent,
        props: {
          title: `آدرس مورد نظر کپی شد`,
          icon: "XOctagonIcon",
          text: `https://${process.env.VUE_APP_BASE_URL.split('https://api.')[1]}/games/` + id + "/" + text.split(' ').join('-'),
          variant: "success",
        },
      });
    },
  },
  mounted() {
    this.getAllGames();
    this.$watch("currentPage", (newCurrentPage) => {
      this.getAllGames();
    });
  },
  unmounted() {
    this.$watch("currentPage");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}
</style>
